import request from "../../utils/request";
/**获取预购轮次的汇总情况
 * @param {object} params
 * @param {int} params.unitId        -单位ID 必填
 */
export function getturnCount(params) {
  return request("/overall/getFurtureIntegrationByCount", {
    method: "get",
    params: params
  });
}

/**获取VIP申请未处理的数量
 * @param {object} params
 * @param {int} params.unitId        -单位ID 必填
 */
export function getApplyByVipCount(params) {
    return request("/overall/getApplyByVipCount", {
      method: "get",
      params: params
    });
  }


/**查询当天租车情况，包括购买总数、已训、未训、正在培训(不包括注销)
 * @param {object} params
 * @param {int} params.unitId        -单位ID 必填
 */
export function getTodayIntegration(params) {
    return request("/overall/getTodayIntegration", {
      method: "get",
      params: params
    });
  }

/**获取最近一周未处理的业务异常申请数量
 * @param {object} params
 * @param {int} params.unitId        -单位ID 必填
 */
export function getApplyByAbnormalCount(params) {
    return request("/overall/getApplyByAbnormalCount", {
      method: "get",
      params: params
    });
  }

/**获取预购轮次的最近一周的大小车轮次情况
 * @param {object} params
 * @param {int} params.unitId        -单位ID 必填
 */
export function getFurtureIntegrationByDay(params) {
    return request("/overall/getFurtureIntegrationByDay", {
      method: "get",
      params: params
    });
  }  